<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4 mb--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('sent') }}</h3>
                        </div>
                        <div class="col-4 text-right">
                            <base-input input-classes="form-control-sm text-center" v-model="sent.search" :placeholder="tt('search_message')"/>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                        <el-table-column :label="tt('sender')" :prop="tt('sender')" sortable>
                            <template v-slot="{row}">
                                {{row.sender}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('subject')" :prop="tt('subject')" sortable>
                            <template v-slot="{row}">
                                {{row.subject}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('message')" :prop="tt('message')" sortable>
                            <template v-slot="{row}">
                                {{row.message}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('date')" :prop="tt('date')" sortable>
                            <template v-slot="{row}">
                                {{row.date}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <router-link to="/message/sent-view" class="dropdown-item" tag="button">
                                            <span class="ml-1">
                                                {{ tt('view') }}
                                            </span>
                                        </router-link>
                                        <el-dropdown-item :command="{action:'remove', data:row.id}">{{ tt('delete') }}</el-dropdown-item>                                        
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="sent.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('description')" :placeholder="tt('description')" v-model="sent.description" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                <base-button type="primary">{{ tt('add') }}</base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    export default {        
        data() {
            return {                
                form: {
                    title: this.tt('add_source_type'),
                    show: false
                },      
                formRelation: {
                    show: false
                }, 
                table: {
                    data: [
                        {
                            id: 1,
                            sender: "Administrator", 
                            subject: "Hallo",
                            message: "Hallo gess", 
                            date: "2020-08-24 15:18:35", 
                        },
                        {
                            id: 2,
                            sender: "Administrator", 
                            subject: "Test",
                            message: "Test nih", 
                            date: "2020-08-24 15:18:35", 
                        },
                        {
                            id: 1,
                            sender: "Administrator", 
                            subject: "Selamat pagi",
                            message: "Selamat pagi semua", 
                            date: "2020-08-24 15:18:35", 
                        },
                    ]
                },        
                sent: {
                    
                }  
            }
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.title = this.tt('add_source_type');
                this.form.show = true;
            },
            edit(id) {
                this.form.title = this.tt('edit_source_type');
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_delete'));
                    } else {
                        this.alertDialog('error', this.tt('error_delete'));
                    }
                })
            },
            relation(id) {
                this.formRelation.show = true;
                this.inbox.serviceGroups = [
                    {
                        id: 901001,
                        service_group: "VEHICLE & O/H EQUIPMENT PARTS"
                    }
                ]
            }
        }   
    };
</script>
<style></style>
